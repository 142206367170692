import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import * as fetch from "isomorphic-fetch";

const cache = new InMemoryCache();

if (typeof window !== "undefined" && window.localStorage) {
  persistCache({
    cache,
    storage: window.localStorage as any,
  });
}

export default new ApolloClient({
  link: new HttpLink({
    fetch,
    uri: "https://7ugnne43t7.execute-api.us-east-1.amazonaws.com/production/graphql",
  }),
  cache,
});
