import * as React from "react";
import { Helmet } from "react-helmet";
import { css, Global } from "@emotion/core";

import { appName, appTagline } from "../constants";
import Header from "./header";

const mainTitle = `${appName} - ${appTagline}`;

const globalStyles = css`
  .papio-spinner {
    -webkit-animation: papio-spinner 2s infinite linear;
    animation: papio-spinner 2s infinite linear;
  }

  @-webkit-keyframes papio-spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes papio-spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  html {
    overflow: hidden;
    height: 100%;
  }

  body {
    height: 100%;
    overflow: auto;

    margin: 0;
    background-image: linear-gradient(
      to left top,
      rgb(0, 0, 0),
      rgb(70, 70, 70)
    );
  }

  h1 {
    margin: 0;
  }

  a {
    color: #ffffff;
  }

  @media only screen and (max-width: 736px) {
    body {
      margin: 0 8px;
    }
  }

  .papio {
    font-family: sans-serif;
    color: #ffffff;

    max-width: 860px;
    min-height: 480px;
    margin: 0 auto;
    position: relative;
  }

  .papio-header > h1 {
    padding: 16px 0 24px 0;
    width: max-content;
  }

  .papio-header > h1:hover {
    cursor: pointer;
  }

  .papio-header > span {
    position: absolute;
    left: 21px;
    top: 48px;
    font-size: 10px;
  }

  .papio-header ul {
    list-style: none;
    display: inline-flex;
    position: absolute;
    right: 8px;
    margin: 28px 0;
    font-size: 20px;
  }

  .papio-header ul li {
    margin: 0 8px;
  }

  .papio-header {
    display: inline-flex;
    width: calc(100% - 32px);
    position: relative;
    height: 80px;
    padding: 0 16px;
  }

  .papio-children {
    padding: 0 16px;
  }

  button {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export default class MainLayout extends React.Component<any> {
  private getMetadata(): { title: string; description: string } {
    switch (this.props.uri) {
      case "/":
        return {
          title: mainTitle,
          description: `Homepage for ${appName} and catalogue of current transcriptions.`
        };
      case "/about":
        return {
          title: `${mainTitle} - About`,
          description: `What is ${appName}`
        };
      case "/recent":
        return {
          title: `${mainTitle} - Recent`,
          description: `Recent Uploads on ${appName}`
        };
      default:
        const { title, description } = this.props.pageContext.recording;
        return { title, description };
    }
  }

  public render() {
    const { title, description } = this.getMetadata();
    return (
      <div className="papio">
        <Global styles={globalStyles} />
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content={description} />
          <title>{title}</title>
        </Helmet>
        {this.props.children}
      </div>
    );
  }
}
