// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */),
  "component---src-templates-recent-tsx": () => import("./../../../src/templates/recent.tsx" /* webpackChunkName: "component---src-templates-recent-tsx" */),
  "component---src-templates-transcription-tsx": () => import("./../../../src/templates/transcription.tsx" /* webpackChunkName: "component---src-templates-transcription-tsx" */)
}

