import { Link, navigate } from "gatsby";
import * as React from "react";
import { appTagline } from "../constants";

export default class Header extends React.Component {
  public render() {
    return (
      <div className="papio-header">
        <h1 onClick={() => navigate("/")}>PAPIO</h1>
        <span>{appTagline}</span>
        <ul>
          <li>
            <Link to="/">Browse</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
        </ul>
      </div>
    );
  }
}
